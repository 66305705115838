export const MainConfig = {
    AppDetails: {
        app_name: "ChiNcoDrive",
        app_description: "ChiNcoDrive Taxi Booking App",
        app_identifier: "com.chinco.drive",
        ios_app_version: "1.0",
        android_app_version: 1
    },
    FirebaseConfig: {
        apiKey: "AIzaSyAqY8NgMQ7kIWEulRL97MdHQUDFqhpPku0",
        authDomain: "chincodrive-b9f51.firebaseapp.com",
        databaseURL: "https://chincodrive-b9f51-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "chincodrive-b9f51",
        storageBucket: "chincodrive-b9f51.appspot.com",
        messagingSenderId: "242601687074",
        appId: "1:242601687074:web:b41e73dcb92a11e6c8b27b",
        measurementId: "G-2H280LLY5C"
    },
    Google_Map_API_Key: {
        ios: "AIzaSyAlCpX_B26ZAUd-m-6CKfRgQvEQMph4qa4",
        android: "AIzaSyAlCpX_B26ZAUd-m-6CKfRgQvEQMph4qa4",
        web: "AIzaSyAlCpX_B26ZAUd-m-6CKfRgQvEQMph4qa4"
    },
    facebookAppId: "544822053237823",
    PurchaseDetails: {
        CodeCanyon_Purchase_Code: "d1e464ca-74cd-48fc-95ae-ab94190ffd01",
        Buyer_Email_Address: "info@chincotrac.com"
    }
}
